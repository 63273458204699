<template>
    <div :class="'tab-pane py-2 py-xl-2 fade show '+ show" :id="'descargas'+this.id" role="tabpanel" :aria-labelledby="'descargas'+this.id+'-tab'">
        <div class="row justify-content-center">

            <div class="mb-3" style="cursor: pointer; display: flex;flex-direction: row;justify-content: center;" >
                <div class="card card-icon mb-1 cardWidth" >
                    <div class="row g-0">
                        <div class="col-auto card-icon-aside bg-info headMessage" style="background-color: white !important; border-right:1px solid lightgray">

                            <img v-if="datos.archivo_tipo == 'pdf'" src="assets/img/icons/pdf.png" style="width: 3rem" >
                            <img v-else-if="datos.archivo_tipo == 'xlsx'" src="assets/img/icons/excel.png" style="width: 3rem" >
                            <img v-else-if="datos.archivo_tipo == 'docx'" src="assets/img/icons/word.png" style="width: 3rem" >
                            <img v-else src="assets/img/icons/none.png" style="width: 3rem" >

                        </div>
                        <div class="col">
                            <div class="card-body py-3" style="margin-top: 10px; !important">
                                <h5 class="card-title" style="font-size: 0.85rem">{{datos.titulo}} - ({{this.getDesc(datos.archivo_nombre)}})</h5>
                                <p class="card-title"  style="font-size: 0.75rem; margin-bottom: 15px">{{datos.descripcion}}</p>
                                <p class="card-text" style="font-size: 0.75rem; color: blue" @click="descargarArchivo(datos.archivo_nombre)">Descargar</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>



<style>

    .title {
        color: black !important;
        cursor: auto;
    }

    .cursor-pointer{
        cursor: pointer;
    }

    .cursor-pointer:hover{
        color: #0061f2;
    }


    .cardWidth {
        width: 70%
    }
@media (max-width: 600px) {

    .cardWidth {
        width: 100%
    }

}

</style>

<script>
import router from '@/router/index.js' // Vue router instance
export default {
    components: {},
    mounted: function () {},
    methods: {
        descargarArchivo(archivo) {
            this.$store.dispatch("descargas/descargarArchivo", archivo);
        },
        getDesc(desc){
            try {
                
                if(desc != null){
                    let rtn='';
                    let descripcion = desc.split("_")
                    for (let index = 1; index < descripcion.length; index++) {
                        rtn = rtn +descripcion[index];
                    }
                    return rtn;
                }
                return desc;
            } catch (error) {
                 return desc;
            }
        }
    },
    computed: {
      
    },
    props: {
        arrayItem:Number,
        id:String,
        show:String,
        datos:Object
    }
}
</script>>
