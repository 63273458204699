<template>
    <div :class="'tab-pane py-2 py-xl-2 fade show '+ show" :id="'descargas'+this.id" role="tabpanel" :aria-labelledby="'descargas'+this.id+'-tab'">
        <div class="row justify-content-center">
            <Descarga v-for="(dato) in this.listaDescargas" :key="dato.id" active="active" :datos=dato />
            <div style="text-align: center;color:rgb(244, 161, 0)" v-if="this.listaDescargas.length == 0">No hay archivos para descargar.</div>
        </div>
    </div>
</template>

<style>

    .title {
        color: black !important;
        cursor: auto;
    }

    .cursor-pointer{
        cursor: pointer;
    }

    .cursor-pointer:hover{
        color: #0061f2;
    }


    .cardWidth {
        width: 70%
    }
@media (max-width: 600px) {

    .cardWidth {
        width: 100%
    }

}

</style>

<script>
import router from '@/router/index.js' // Vue router instance
import Descarga from '../descargas/descargaComp.vue'

export default {
    components: {Descarga},
    mounted: function () {},
    methods: {
    },
    computed: {
      
    },
    props: {
        arrayItem:Number,
        id:String,
        show:String,
        listaDescargas:Array

    }
}
</script>>
