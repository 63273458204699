<template>
<div>
    <!-- navbar -->
    <Navbar />
    <div id="layoutSidenav">
        <Menu />
        <div id="layoutSidenav_content">
            <main>
                <!-- header -->
                <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div class="container-xl px-4">
                        <div class="page-header-content pt-4">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-auto mt-4">
                                    <h1 class="page-header-title">
                                        <div class="page-header-icon"><i class="fas fa-envelope"></i></div>
                                        Descargas
                                    </h1>
                                    <div class="page-header-subtitle">Descargas</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <!-- fin header -->
                <!-- body -->
                <div class="container-xl px-4 mt-n10">
                    <!-- Wizard card navigation-->
                    <div class="card">
                        <div class="card-header border-bottom">
                            <!-- Wizard navigation-->
                            <div class="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard" id="cardTab" role="tablist">
                                <!-- Wizard navigation item 1-->
                                <CardButton title="Manuales" active="active" subTitle="Manuales" id="descargasA" numberCard="1" />
                                <!-- Wizard navigation item 2-->
                                <CardButton title="Términos y Condiciones" active="" subTitle="Términos y Condiciones" id="descargasB" numberCard="2" />
                                <!-- Wizard navigation item 2-->
                                <CardButton title="Otros" active="" subTitle="Otros" id="descargasC" numberCard="3" />
                            </div>
                        </div>
                        <div class="card-body mt-0">
                            <div class="tab-content" id="cardTabContent">
                                <!-- Wizard item 1-->
                                <Descargas show="active" :arrayItem="1" id="A" :listaDescargas=listaManuales />
                                <Descargas show="" :arrayItem="2" id="B" :listaDescargas=listaTerminos />
                                <Descargas show="" :arrayItem="3" id="C" :listaDescargas=listaOtros />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- fin body -->
            </main>
        </div>
    </div>

</div>
</template>

<style>

.card-body {
    margin-top: 30px;
}

</style>

<script>
import router from '@/router/index.js' // Vue router instance
import spinner from '../../components/spinner'
import Vue from 'vue'
import Navbar from '../../components/home/navbar/navbar'
import Menu from '../../components/home/menu'
import CardButton from '../../components/home/step/util/cardButton'
import Descargas from '../../components/home/step/descargas/descargas'
import Descarga from '../../components/home/step/descargas/descargaComp'

export default {
    name: "Home",
    components: {
        spinner,
        Navbar,
        Menu,
        CardButton,
        Descargas,
        Descarga
    },
    mounted: function () {
        this.findDescargasByTipoProveedor();
    },
    methods: {
        findDescargasByTipoProveedor() {
            this.$store.dispatch("descargas/findDescargasByTipoProveedor")
        }
    },
    computed: {
        listaManuales: {
            get() {
                return this.$store.state.descargas.manuales;
            }
        },        
        listaTerminos: {
            get() {
                return this.$store.state.descargas.terminos;
            }
        },
        listaOtros: {
            get() {
                return this.$store.state.descargas.otros;
            }
        },
    },
};
</script>
